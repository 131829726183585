export class HonorModel {
    constructor(id, name, description, heart = 0, share = 0, content, image, apartment, liked, shared, title = '') {
        this.id = id;
        this.name = name;
        this.description = description;
        this.heart = heart;
        this.countShare = share;
        this.content = content;
        this.image = image;
        this.apartment = apartment;
        this.liked = liked;
        this.shared = shared;
        this.title = title;
    }
}

export class CommentModel {
    constructor(id, avatar, userName, date, comment) {
        this.id = id;
        this.avatar = avatar;
        this.name = userName;
        this.date = date;
        this.comment = comment;
        this.showMoreComment = comment.length > 150;
    }
}