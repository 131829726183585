<template>
  <div class="loading">
    <slot></slot>
    <span class="border"></span>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.loading {
  background-size: 130%;
  position: relative;
}
.border {
  display: none;
  clip: rect(0,0,0,0);
  animation: maskBorder 2s linear infinite;
  z-index: 5;
  border-radius: 45px;
  position: absolute;
  left: 0; top: 0; bottom: 0; right: 0;
}
.loading:hover .border {
  display: block;
}
@keyframes maskBorder {
  0% { clip: rect(0, 0, 2000px, 0); }
  25% { clip: rect(0 , 0, 0, 2000px); }
  50% { clip: rect(0, 0, 2000px, 0); }
  75% { clip: rect(0, 2000px, 0, 0); }
  100% { clip: rect(2000px, 0, 0, 0); }
}
@media only screen and (max-width: 767px) {
  .border {
    border-radius: 20px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1280px) {
  .border {
    border-radius: 35px;
  }
}
</style>