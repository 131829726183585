<template>
  <CardAnimation>
    <div class="card">
      <img class="image-staff"  :src="okrItem?.image" alt="" />
      <div class="card-content">
        <div @click="redirectToHonorDetail(okrItem?.id)">
          <span class="title line-clamp-1">{{ okrItem?.name}}</span>
          <span class="line-clamp-1 description">{{ okrItem?.description }}</span>
        </div>
        <ActionComponent :post="okrItem" type-post="okr"/>
        <label class="line"></label>
        <p class="apartment-content line-clamp-4" @click="redirectToHonorDetail(okrItem?.id)">{{ okrItem?.content }}</p>
      </div>
      <div class="card-content__blur"></div>
    </div>
  </CardAnimation>
</template>

<script>
import ActionComponent from "@/views/components/honors/ActionComponent.vue";
import CardAnimation from "@/views/components/honors/CardAnimation.vue";

export default {
  components: {CardAnimation, ActionComponent},
  props: {
    okrItem: {
      type: Object,
      required: true,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {}
    }
  },
  mounted() {

  },
  methods: {
    redirectToHonorDetail(idStaff) {
      this.$router.push({
        name: 'honor-detail',
        params: {id: idStaff},
        query: {type: 'okr'}
      })
    },
  }
}
</script>

<style lang="css" scoped>
.card {
  overflow: hidden;
  position: relative;
  height: 515px;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 45px;
  border: none;
  padding: 32px;
  background-size: cover;
}
.title {
  font-size: 26px;
  color: #FCB813;
  cursor: pointer;
}
.description {
  font-size: 17px;
  color: white;
  margin-bottom: 10px;
  cursor: pointer;
}
.line {
  height: 1px;
  background: white;
  margin: 16px 0;
  width: 100%;
}
.card-content {
  z-index: 1;
  position: absolute;
  top: 50%;
  right: 32px;
  left: 32px;
}
.apartment-content {
  font-size: 16px;
  color: white;
  cursor: pointer;
}
.card-content__blur {
  position: absolute;
  width: 100%;
  height: 350px;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.9;
  background-image: linear-gradient(to bottom, rgba(255,0,0,0), #422abd);
}
.image-staff {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media only screen and (max-width: 767px) {
  .apartment-content {
    display: none;
  }
  .card {
    height: 250px;
    border-radius: 20px;
  }
  .card-content {
    top: 60%;
    right: 14px;
    left: 14px;
  }
  .line {
    display: none;
  }
  .title {
    font-size: 18px;
  }
  .description {
    font-size: 16px;
  }
  .card-content__blur {
    height: 180px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1280px) {
  .card {
    height: 375px;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 35px;
  }
  .card-content {
    top: 30%;
    right: 32px;
    left: 32px;
  }
}
</style>